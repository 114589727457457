import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const headerAnimation = (el) => {
  gsap.fromTo(
    el,
    { x: -100, y: -20, autoAlpha: 0 },
    {
      duration: 0.6,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: 'power',
      overwrite: 'auto',
    }
  );
};
const animateFrom = (el, direction) => {
  direction = direction || 1;
  let x = 0;
  let y = direction * 100;

  if (el.classList.contains('from-left')) {
    x = -100;
    y = 0;
  } else if (el.classList.contains('from-right')) {
    x = 100;
    y = 0;
  }

  el.style.transform = `translate(${x}px, ${y}px)`;
  el.style.opacity = 0;

  gsap.fromTo(
    el,
    { x, y, opacity: 0 },
    {
      duration: 1,
      x: 0,
      y: 0,
      opacity: 1,
      ease: 'sine',
      overwrite: 'auto',
    }
  );
};
const hide = (el) => {
  gsap.set(el, { opacity: 0 });
};

// const targetDiv = document.querySelector('#stack-grid');
const stackDivs = [...document.getElementsByClassName('stack-card')];
const projectDivs = [...document.getElementsByClassName('project-card')];
const stackHeader = document.querySelector('#stack-header');
const projectHeader = document.querySelector('#project-header');

const animateHeader = (target) => {
  ScrollTrigger.create({
    trigger: target,
    onEnter: () => {
      hide(target);
      headerAnimation(target);
    },
    onEnterBack: () => {
      hide(target);
      headerAnimation(target);
    },
    onLeave: () => {
      hide(target);
    },
  });
};

const stackAnimation = (target) => {
  target.forEach((el) => {
    hide(el);
    ScrollTrigger.create({
      trigger: el,
      onEnter: () => {
        hide(el);
        animateFrom(el);
      },
      onEnterBack: () => {
        hide(el);
        animateFrom(el, -1);
      },
      onLeave: () => {
        hide(el);
      },
      onLeaveBack: () => {
        hide(el);
      },
    });
  });
};

animateHeader(stackHeader);
animateHeader(projectHeader);

stackAnimation(stackDivs);
stackAnimation(projectDivs);
